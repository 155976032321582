<template>
  <div
    class="va_modal__memory"
    @click.self="
      showContactFormMemory();
      $emit('close');
    "
  >
    <button
      class="vm-modal__close"
      @click="
        showContactFormMemory();
        $emit('close');
      "
    >
      <span uk-icon="icon: close; ratio: 1.5"></span>
    </button>
    <div class="slide__media">
      <template>
        <div class="media">
          <div
            class="vx-ratio-box mobile_fullscreen vx-ratio-box-1-1 uk-background-muted"
          >
            <iframe :src="strings.memory_game_link" frameborder="0"></iframe>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ContactFormMemory from "./ContactFormMemory.vue";
// import { bus } from "../main";
export default {
  name: "MemoryGame",
  props: {},
  data: function() {
    return {
      strings: this._vaData.strings,
      form: this._vaData.strings.forms.find(
        f => f.form_id === "default_contact_form"
      )
    };
  },
  methods: {
    showContactFormMemory() {
      console.log("kontakt form anzeigen");
      this.form.referer = "Memory Game Registrierung";

      this.$modal.show(
        ContactFormMemory,
        {
          form: this.form
        },
        {
          height: "auto",
          adaptive: true,
          scrollable: true
        }
      );
    }
  }
};
</script>

<style scoped lang="scss">
.title {
  @extend h2;
}
.mobile_fullscreen {
  padding-top: 0 !important;
  height: 100%;
  // height: 90vh;
  // @media (min-height: 1000px) {
  //   height: 70vh;
  // }
  // @media (max-height: 800px) {
  //   height: 70vh;
  // }
  // @media (max-width: 1000px) {
  //   height: 70vh;
  // }
}
.slide__media {
  height: 100%;
}
.media {
  height: 100%;
}
iframe {
}
.vm-modal__close {
  top: 6.5%;
  right: 6%;
}
.vm--overlay {
  pointer-events: none;
}
.va_modal__memory {
  overflow: hidden;
  padding: 2.5% 5% !important;
  max-width: 90% !important;
  width: 90% !important;
  height: 95% !important;
  max-height: 90% !important;
}
</style>
